angular.module('JC.directives.datatable', ['JC.services.utilities', 'JC.services.form'])
    .directive('afgDataTable', [
        '$resource',
        '$timeout',
        'Base64',
        '$http',
        'Mobile',
        '$window',
        '$filter',
        '$xdr',
        '$log',
        '$rootScope',
    function ($resource, $timeout, Base64, $http, Mobile, $window, $filter, $xdr, $log, $rootScope) {

        //api-url="http://silt.rogue.afg/afc.api/"
        /*base64-string="W3sNCiAgICAiQXBpUmVzb3VyY2VJZCI6IDEsDQogICAgIkNvbXBhbnkiOiAiYWZjIiwNCiAgICAiQXBwbGljYXRpb24iOiAibm90aWZpY2F0aW9ucyIsDQogICAgIk5hbWUiOiAibm90aWZpY2F0aW9ucyIsDQogICAgIkRlbGVnYXRlQXBpIjogIkFmYy5BcGkuRG9tYWluLlNxbEFkYXB0ZXIiLA0KICAgICJEZWZhdWx0TGF5b3V0IjogMSwNCiAgICAiVXJpIjogImh0dHA6Ly9zaWx0LnJvZ3VlLmFmZy9hZmMuYXBpL2FmYy9ub3RpZmljYXRpb25zIg0KfSwgew0KICAgICJBcGlSZXNvdXJjZUlkIjogMywNCiAgICAiQ29tcGFueSI6ICJhZmMiLA0KICAgICJBcHBsaWNhdGlvbiI6ICJhcGkiLA0KICAgICJOYW1lIjogImxheW91dCIsDQogICAgIkRlbGVnYXRlQXBpIjogIkFmYy5BcGkuRG9tYWluLlNxbEFkYXB0ZXIiLA0KICAgICJEZWZhdWx0TGF5b3V0IjogLTEsDQogICAgIlVyaSI6ICJodHRwOi8vc2lsdC5yb2d1ZS5hZmcvYWZjLmFwaS9hZmMvYXBpIg0KfSwgew0KICAgICJBcGlSZXNvdXJjZUlkIjogNSwNCiAgICAiQ29tcGFueSI6ICJhZmMiLA0KICAgICJBcHBsaWNhdGlvbiI6ICJmbGV4IiwNCiAgICAiTmFtZSI6ICJmbGV4IiwNCiAgICAiRGVsZWdhdGVBcGkiOiAiQWZjLkFwaS5Eb21haW4uU3FsQWRhcHRlciIsDQogICAgIkRlZmF1bHRMYXlvdXQiOiAtMSwNCiAgICAiVXJpIjogImh0dHA6Ly9zaWx0LnJvZ3VlLmFmZy9hZmMuYXBpL2FmYy9mbGV4Ig0KfSwgew0KICAgICJBcGlSZXNvdXJjZUlkIjogNiwNCiAgICAiQ29tcGFueSI6ICJhZmMiLA0KICAgICJBcHBsaWNhdGlvbiI6ICJhdXRoZW50aWNhdGUiLA0KICAgICJOYW1lIjogImF1dGhlbnRpY2F0ZSIsDQogICAgIkRlbGVnYXRlQXBpIjogIkFmYy5BcGkuRGVsZWdhdGVBcGlzLkF1dGhlbnRpY2F0ZURlbGVnYXRlIiwNCiAgICAiRGVmYXVsdExheW91dCI6IC0xLA0KICAgICJVcmkiOiAiaHR0cDovL3NpbHQucm9ndWUuYWZnL2FmYy5hcGkvYWZjL2F1dGhlbnRpY2F0ZSINCn0sIHsNCiAgICAiQXBpUmVzb3VyY2VJZCI6IDksDQogICAgIkNvbXBhbnkiOiAiYWZjIiwNCiAgICAiQXBwbGljYXRpb24iOiAiZmlsZXZhdWx0IiwNCiAgICAiTmFtZSI6ICJmaWxldmF1bHQiLA0KICAgICJEZWxlZ2F0ZUFwaSI6ICJBZmMuQXBpLkRvbWFpbi5TcWxBZGFwdGVyIiwNCiAgICAiRGVmYXVsdExheW91dCI6IC0xLA0KICAgICJVcmkiOiAiaHR0cDovL3NpbHQucm9ndWUuYWZnL2FmYy5hcGkvYWZjL2ZpbGV2YXVsdCINCn1d" */
        /*json-string='[{"ApiResourceId":1,"Company":"afc","Application":"notifications","Name":"notifications","DelegateApi":"Afc.Api.Domain.SqlAdapter","DefaultLayout":1,"Uri":"http://silt.rogue.afg/afc.api/afc/notifications"},{"ApiResourceId":3,"Company":"afc","Application":"api","Name":"layout","DelegateApi":"Afc.Api.Domain.SqlAdapter","DefaultLayout":-1,"Uri":"http://silt.rogue.afg/afc.api/afc/api"},{"ApiResourceId":5,"Company":"afc","Application":"flex","Name":"flex","DelegateApi":"Afc.Api.Domain.SqlAdapter","DefaultLayout":-1,"Uri":"http://silt.rogue.afg/afc.api/afc/flex"},{"ApiResourceId":6,"Company":"afc","Application":"authenticate","Name":"authenticate","DelegateApi":"Afc.Api.DelegateApis.AuthenticateDelegate","DefaultLayout":-1,"Uri":"http://silt.rogue.afg/afc.api/afc/authenticate"},{"ApiResourceId":9,"Company":"afc","Application":"filevault","Name":"filevault","DelegateApi":"Afc.Api.Domain.SqlAdapter","DefaultLayout":-1,"Uri":"http://silt.rogue.afg/afc.api/afc/filevault"}]'*/
        var originalLayout,
            originalMobileLayout,
            originalItems,
            originalRowClicks,
            originalClasses;

        return {
            templateUrl: "/js/views/data-table-wrapper.html",
            restrict: 'AE',
            scope: true,
            transclude: true,
            controller: function ($scope, $transclude) {
                //get original elemtents
                originalItems = [];
                originalLayout = [];
                originalRowClicks = [];
                originalMobileLayout = [];
                originalClasses = "";


                $transclude(function (clone, scope) {
                    angular.forEach(clone, function (value, key) {
                        var el = angular.element(value);
                        if (el.html() !== undefined) {
                            var head = el.find("th"),
                                rows = el.find("tbody").find("tr");

                            originalClasses = value.className.replace("ng-scope", "");

                            //load object layout from head
                            angular.forEach(head, function (value, index) {
                                var col = angular.element(value),
                                    mobile = col.attr("data-mobile-view");

                                originalLayout.push({ label: col.html(), column: col.attr("data-column") });

                                switch (mobile) {
                                    case "main":
                                        originalMobileLayout[0] = col.attr("data-column");
                                        break;
                                    case "right":
                                        originalMobileLayout[1] = col.attr("data-column");
                                        break;
                                    case "left":
                                        originalMobileLayout[2] = col.attr("data-column");
                                        break;
                                }
                            });
                            
                            //load view model from existing elements
                            angular.forEach(rows, function (value, index) {
                                var columns = angular.element(value).find("td"),
                                    row = angular.element(value),
                                    rowObject = {},
                                    rowClick = [];

                                angular.forEach(columns, function (value, key) {
                                    var column = angular.element(value),
                                        prop = originalLayout[key].column,
                                        click = (column.attr("data-href")) ? column.attr("data-href") : angular.element(value).attr("data-href");
                                    rowClick.push(click);
                                    var data = column.text().trim();
                                    if (!column.attr("data-type")) {
                                        var date = Date.parse(data);
                                        if (column.html().trim().indexOf("btn") > -1) {
                                            data = column.html();
                                        } else if (Number(parseFloat(data)) && isFinite(data)) {
                                            data = Number(parseFloat(data));
                                        } else if (date && data != 0 && (value.toString().indexOf("/") > -1 || value.toString().indexOf("-") > -1)) {
                                            data = new Date(date);
                                        }
                                    }
									
                                    rowObject[prop] = data; //column.html();
									
                                });
								if(Object.keys(rowObject).length > 0){
									originalItems.push(rowObject);
								}
                            });
                        }
                    });
                });

            },
            link: function (scope, element, attributes) {

                //default directive options
                scope.options = {

                    //required

                    jsonString: null,
                    base64String: null,
                    apiUrl: null,

                    layout: null,
                    mobileLayout: null,
                    clickUrl: null,
                    clickTarget: '_self',
                    itemId: null,

                    //submit
                    updateUrl: null,
                    createUrl: null,
                    deleteUrl: null,

                    //Paging

                    limit: "10",
                    paging: 'true',

                    //Searching

                    searchable: 'true',
                    columnSearchable: false,
                    hideIfEmpty: false,

                    editable: false,
                    editInline: false,
                    editIgnore: "[]",
                    canDelete: false,
                    canCreate: false,

                    gridView: false,

                    dateFormate: "",

                    //Sorting

                    sortable: 'true',
                    sortIgnore: "[]", // not functional yet



                    sortColumn: null,
                    sortDirection: false,

                    //Classes

                    gridColumnCount: '3',

                    striped: 'true',
                    bordered: 'true',
                    hover: 'true',
                    condensed: false,
                    responsive: 'true',

                    //Messages
                    emptyMessage: "No records were found.",
                    loadingMessage: "Loading...",
                    searchPlaceholder: "Filter Results",

                    clickIgnore:"[]",

                    //Callbacks


                    onRender: null,
                    onLoad: null,
                    onChange: null,
                    onLoadError: null,
                    onChangePage: null,
                    onRowClick: null,
                    onSave: null,
                    onDelete: null,

                    //Overrides
                    overrideCreateClick: null,
                    overrideData: null,
                    overrideRowClick: null,
                    overrideRowEdit: null,
                    overrideRowDelete: null

                };

                //extend options with attributes and add to scope
                scope.options = angular.extend(scope.options, attributes);

                //if there is no url or layout info, throw error.
                if ((!scope.options.apiUrl && scope.options.base64String && scope.options.jsonString && originalItems.length == 0) || (!scope.options.layout && originalLayout.length == 0)) {
                    $log.error("api-url is a required attribute for this directive.");
                }


                scope.contentUrl = "/js/views/data-table.html";
                if (scope.options.gridView == "true") {
                    scope.contentUrl = "/js/views/data-grid.html";
                }


                //build scope parameters
                scope.options.layout = (originalLayout.length > 0) ? originalLayout : angular.fromJson(scope.options.layout);
                scope.options.mobileLayout = (originalMobileLayout.length > 0) ? originalMobileLayout : angular.fromJson(scope.options.mobileLayout);
                scope.options.clickIgnore = angular.fromJson(scope.options.clickIgnore);
                scope.options.hideIfEmpty = (scope.options.hideIfEmpty == true || scope.options.hideIfEmpty == "true") ? true:  false;
                scope.items = [];
                scope.displayItems = [];
                scope.totalPages = 0;
                scope.init = true;
                scope.loading = true;
                scope.empty = false;
                

                $rootScope.tableCount++;
                scope.tableCount = $rootScope.tableCount;

                var directionType = typeof scope.options.sortDirection
                if(directionType = "string"){
                    if(scope.options.sortDirection == "desc" ){
                        scope.sortDirection = true
                    } else {
                        scope.sortDirection = false
                    }
                } else {
                    scope.sortDirection = scope.options.sortDirection;
                }

                scope.sortColumn = scope.options.sortColumn;
                scope.currentPage = 0;
                scope.query = {};
                scope.formHtml = '';
                scope.modalShow = false;
                scope.pagedItems = [];
                scope.options.limit = parseInt(scope.options.limit);

                ////DEBUG
                $log.debug("Layout");
                $log.debug(scope.options.layout);

                scope.isMobile = Mobile.isMobile();

                angular.element($window).on("resize", function () {
                    scope.isMobile = Mobile.isMobile();
                });

                //build classes for table
                scope.tableClass = originalClasses;
                scope.tableClass += (scope.options.sortable === 'true') ? "table-sortable" : "";
                scope.tableClass += (scope.options.bordered === 'true') ? " table-bordered" : "";
                scope.tableClass += (scope.options.striped === 'true') ? " table-striped" : "";
                scope.tableClass += (scope.options.hover === 'true') ? " table-hover" : "";
                scope.tableClass += (scope.options.condensed === 'true') ? " table-condensed" : "";
                scope.tableClass += (scope.options.searchable === 'true') ? " table-searchable" : "";

                scope.$watch(function () {
                    Mobile.fixClasses();
                });
                //populate table with data

                if (typeof window[scope.options.overrideData] == "function") {
                    window[scope.options.overrideData](scope, $http, function (data) {
                        scope.loading = false;

                        if(scope.$$phase || scope.$root.$$phase){
                            scope.items = data;
                        } else {
                            scope.$apply(function(){
                                scope.items = data;
                            })
                        }
                        
                    }, angular);
                } else {

                    if (originalItems.length > 0) {
                        scope.items = originalItems;
                        scope.loading = false;
                    } else if (scope.options.base64String) {
                        try {
                            scope.items = angular.fromJson(Base64.decode(scope.options.base64String));
                            scope.loading = false;
                        } catch (e) {
                            if (typeof (window[scope.options.onLoadError]) === "function") {
                                window[scope.options.onLoadError](e.message, scope, angular);
                            }
                        }
                    } else if (scope.options.jsonString) {
                        try {
                            scope.items = angular.fromJson(scope.options.jsonString);
                            scope.loading = false;
                        } catch (e) {
                            if (typeof (window[scope.options.onLoadError]) === "function") {
                                window[scope.options.onLoadError](e.message, scope, angular);
                            }
                        }
                    } else {
                        var url = scope.options.apiUrl,
                            htmlElement = angular.element(document.getElementById("ng-app"));
                            if(url){
                        $http.get(url)
                            .success(function (result) {
                                // success
                                var isString = typeof result;
                                if(isString == "string"){

                                    scope.items = [];
                                    scope.displayItems = [];
                                    // fail
                                    scope.empty = true;
                                    if (typeof (window[scope.options.onLoad]) === "function") {
                                        window[scope.options.onLoad]([], scope, $http, function (_scope) {
                                            scope = _scope;
                                        }, angular);
                                    }
                                    if (typeof (window[scope.options.onLoadError]) === "function") {
                                        window[scope.options.onLoadError](result, scope, $http, angular);
                                    }


                                } else {
                                    scope.items = result;
                                    scope.displayItems = result;
                                    scope.empty = (result.length > 0) ? false : true;

                                    ////DEBUG
                                    $log.debug("Url return");
                                    $log.debug(result);
                                    $log.debug("scope items");
                                    $log.debug(scope.items);

                                    if (typeof (window[scope.options.onLoad]) === "function") {
                                        window[scope.options.onLoad](result, scope, $http, function (_scope) {
                                            scope = _scope;
                                        }, angular);
                                    }
                                }
                            })
                            .error(function (errResponse) {
                                $log.error(errResponse);

                                scope.items = [];
                                scope.displayItems = [];
                                // fail
                                scope.empty = true;
                                if (typeof (window[scope.options.onLoadError]) === "function") {
                                    window[scope.options.onLoadError](errResponse, scope, $http, angular);
                                }
                            })['finally'](function () {
                                //always
                                scope.loading = false;
                            });
                        }
                    }
                }

                if (typeof (window[scope.options.onRender]) === "function") {
                    window[scope.options.onRender](scope, function (_scope) {
                        scope = _scope;
                    }, angular);
                }

                scope.$watchCollection("query", function (newValue, oldValue) {
                    scope.displayItems = $filter('filter')(scope.items, scope.query);
                    scope.totalPages = Math.ceil(scope.displayItems.length / scope.options.limit);
                    if (scope.displayItems.length === 0) {
                        scope.empty = true;
                    } else {
                        scope.empty = false;
                    }
                    scope.sort();
                    scope.pageItems();
                });

                scope.$watchCollection('items', function (changes, original) {
                    if (scope.items.length == 0) {
                        scope.empty = true;
                    }

                    scope.empty = false;
                    scope.displayItems = [];

                    scope.totalPages = Math.ceil(scope.items.length / scope.options.limit);

                    angular.forEach(scope.items, function (item, index) {
                        var display = {};

                        angular.forEach(item, function (value, key) {
                            var date = Date.parse(value);
                            if(Number(parseFloat(value)) && isFinite(value)){
                                value == Number(value);
                            } else if(date && value != 0 && (value.toString().indexOf("/") > -1 || value.toString().indexOf("-") > -1)){
								value = new Date(date);
							}
                            display[key] = value;
                            scope.items[index][key] = value;



                        });
                        scope.displayItems[index] = display;
                    });

                    

                    if (typeof (window[scope.options.onChange]) === "function") {
                        window[scope.options.onChange](scope, $http, function (_scope) {
                            scope = _scope;
                        }, angular);
                    }
                    scope.sort();
                    scope.pageItems();
                });

                function isNumber(n) {
                  return !Number(parseFloat(n)) && isFinite(n);
                }

                scope.filteredItems = [];
                

                scope.$watch('currentPage + options.limit', function() {
                    scope.pageItems();
                });


                scope.pageItems = function(){
                    scope.filteredItems = [];
                    var begin = (scope.currentPage * scope.options.limit), 
                        end = begin + scope.options.limit;

                    scope.filteredItems = scope.displayItems.slice(begin, end);
                }
                scope.pageItems();

                //scope functions
                scope.columnClasses = function (column, index) {
                    var classes = "";
                    if (scope.options.sortable === 'true') {
                        classes += "sortable ";
                        classes += (scope.sortColumn == column.column) ? "active-sort" : "";
                        classes += (scope.sortDirection && scope.sortColumn == column.column) ? " descending" : " ascending";
                    }

                    if (index > 0) {
                        classes += " hidden-xs";
                    }
                    return classes;
                }
                scope.getDisplayValue = function (item, column) {
                    var index = scope.items.indexOf(item),
                        value = item[column];

                    if (value instanceof Date) {
                        value = dateFormatter(item[column]);
                    }
                    return value;
                }

                function dateFormatter(date) {
					var day = (date.getUTCDate() < 10) ? "0" + date.getUTCDate() : date.getUTCDate()
					return [('0' + (date.getUTCMonth() + 1)).slice(-2), day, (date.getUTCFullYear())].join('/');
                }

                scope.getMobileDisplayValue = function(item, column) {
                    if(typeof column == "string") {

                        return scope.getDisplayValue(item, column);
                        
                    } else if(typeof column == "object") {

                        var returnString = ""
                        for(var i in column){
                            if(i == 0){
                                returnString = column[i];
                            } else {
                                var find = "%"+i;
                                if(scope.getDisplayValue(item, column[i]) === undefined){
                                    returnString = returnString.replace(find, "");
                                } else {
                                    returnString = returnString.replace(find, scope.getDisplayValue(item, column[i]));
                                }
                            }
                        }

                        return returnString;
                    }
                }

                scope.columnCount = function () {
                    return (scope.options.editable === 'true') ? scope.options.layout.length + 1 : scope.options.layout.length;
                }

                scope.cancelQuery = function () {
                    scope.query = {};
                }

                scope.sortByColumn = function (column) {
                    scope.sortDirection = !scope.sortDirection;
                    scope.sortColumn = column.column;
                    scope.currentPage = 0;

                    scope.sort();
                    scope.pageItems();
                }

                scope.sort = function(){
                    scope.displayItems = $filter('orderBy')(scope.displayItems, scope.sortColumn, scope.sortDirection);
                    
                }

                scope.gridColumnClass = function () {
                    return "col-sm-" + (12 / Number(scope.options.gridColumnCount));
                }



                scope.goToPage = function (page) {
                    if (page >= 0 && page <= scope.items.length / scope.options.limit) {
                        if (typeof (window[scope.options.onChangePage]) == "function") {
                            window[scope.options.onChangePage](page, scope.options.limit, scope, function (_scope) {
                                scope = _scope;
                            }, angular);
                        }
                        scope.currentPage = page;
                    }
                }

                scope.isDisabled = function (column) {
                    var ignoreList = angular.fromJson(scope.options.editIgnore);
                    return (angular.fromJson(scope.options.editIgnore).indexOf(column.toLowerCase()) != -1) ? true : false;
                }

                scope.editing = false;

                scope.editClick = function (item, e) {
                    if (typeof window[scope.options.overrideRowEdit] == "function") {
                        window[scope.options.overrideRowEdit](item, scope, $http, function (item, _scope) {
                            scope = _scope;
                        }, angular);
                    } else {
                        if (scope.options.editInline === 'true') {
                            scope.modalShow = true;
                            scope.editing = item;
                        } else {
                            scope.clickRow(item);
                        }
                    }
                }

                scope.cancelEdit = function (item, index) {

                    if (item.allNew == "1") {
                        for (var i in scope.items) {
                            if (scope.items[i].allNew == "1") {
                                scope.items.splice(i, 1);
                            }
                        }
                    }
                    scope.editing = false;
                    hideModal
                }

                var hideModal = function () {
                    $timeout(function () {
                        scope.modalShow = false;
                    }, 1000);
                }

                scope.addItem = function () {

                    if (typeof (window[scope.options.overrideCreateClick]) === "function") {
                        window[scope.options.overrideCreateClick](scope, $http, function (_scope) {
                            scope = _scope;
                        }, angular);
                    } else {
                        var item = {};

                        for (var i in scope.options.layout) {
                            item[scope.options.layout[i].column] == "";
                            item.allNew = "1";
                        }

                        scope.items.push(item);

                        scope.sortColumn = "allNew";
                        scope.sortDirection = false;
                        scope.editing = item;
                    }
                }

                scope.submitUpdate = function (item) {
                    var url = (item.allNew === undefined) ? scope.options.updateUrl : scope.options.createUrl,
                        method = (item.allNew === undefined) ? "PUT" : "POST",
                        isNew = (item.allNew === undefined) ? false : true;

                    scope.editing = false;
                    hideModal();

                    if (url) {

                        delete item.allNew;
                        delete item._display;

                        var url = scope.options.updateUrl,
                            split = scope.options.updateUrl.split(/:|&|\//);

                        for (var i in split) {
                            for (var n in item) {
                                if (split[i] == n) {
                                    url = url.replace(":" + n, item[n]);
                                }
                            }
                        }

                        if (scope.options.updateUrl) {
                            scope.loading = true;
                            $http({ method: method, url: scope.options.updateUrl, data: item })
                                .success(function (result, status, headers, config) {
                                    if (isNew) {
                                        scope.sortColumn = scope.options.layout[0].column;
                                        scope.currentPage = 0;
                                        scope.sortDirection = true;
                                    }
                                    if (typeof window[scope.options.onSave] == "function") {
                                        window[scope.options.onSave](result, item, scope, function (item, _scope) {
                                            scope = _scope;
                                        }, angular);
                                    }
                                })
                                .error(function (data, status, headers, config) {
                                    alert("Could not save item. status: " + status);
                                })['finally'](function () {
                                    scope.loading = false;
                                });
                        }

                    } else {
                        return null;
                    }

                }

                scope.deleteItem = function (item, index, e) {
                    if (typeof window[scope.options.overrideRowDelete] == "function") {
                        window[scope.options.overrideRowDelete](item, index, element, scope, $http, function (_scope) {
                            scope = _scope;
                        }, angular);
                    } else {
                        if (scope.options.canDelete == "true" && scope.options.deleteUrl) {

                            var c = confirm("Delete item?");
                            if (c == true) {
                                var url = scope.options.deleteUrl,
                                    split = scope.options.deleteUrl.split(/:|&|\//);

                                for (var i in split) {
                                    for (var n in item) {
                                        if (split[i] == n) {
                                            url = url.replace(":" + n, item[n]);
                                        }
                                    }
                                }
                                //http delete
                                scope.editing = false;

                                if (scope.options.deleteUrl) {
                                    scope.loading = true;
                                    $http({ method: 'DELETE', url: scope.options.deleteUrl })
                                        .success(function (data, status, headers, config) {
                                            scope.items.splice(index, 1);
                                            if (typeof scope.options.onDelete == "function") {
                                                window[scope.options.onDelete](result, row, scope, function (_scope) {
                                                    scope = _scope;
                                                }, angular);
                                            }
                                        })
                                        .error(function (data, status, headers, config) {
                                            alert("Could not delete item. status: " + status);
                                        })['finally'](function () {
                                            scope.loading = false;

                                        });
                                }


                            }
                        }
                    }
                }

                scope.rowUrl = function (item, columnIndex, layout) {
                    var itemIndex = scope.items.indexOf(item);
                    if (itemIndex > -1 && originalRowClicks[itemIndex] != undefined) {
                        var url = originalRowClicks[itemIndex][columnIndex];
                        return (url && url != "#" && url != undefined) ? url : null;
                    } else if(scope.options.clickIgnore.indexOf(layout.column) > -1){
                        return null;
                    } else if (scope.options.clickUrl) {
                        var url = scope.options.clickUrl,
                            split = scope.options.clickUrl.split(/:|&|\//);

                        for (var i in split) {
                            for (var n in item) {
                                if (split[i] == n) {
                                    url = url.replace(":" + n, item[n]);
                                }
                            }
                        }
                        return url;
                    } else {
                        return null;
                    }
                }

                scope.clickRow = function (item, index, layout) {
                    if (typeof window[scope.options.overrideRowClick] == "function") {
                        window[scope.options.overrideRowClick](item, scope, $http, function (item, _scope) {
                            scope = _scope;
                        }, angular);
                    } else {
                        if (scope.editing === false) {
                            var url = scope.rowUrl(item, index, layout);

                            ////DEBUG
                            $log.debug("Click Url");
                            $log.debug(url);


                            if (url) {
                                
                                window.open(
                                  url,
                                  scope.options.clickTarget // <- This is what makes it open in a new window.
                                );
                            } else {
                                return null;
                            }
                        }
                    }
                }

            }
        }
    }]);