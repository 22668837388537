angular.module('JC.filters', ['JC.services.utilities'])
	.filter('interpolate', ['version', function (version) {
	    return function (text) {
	        return String(text).replace(/\%VERSION\%/mg, version)
	    };
	}])
    .filter('startFrom', function () {
        return function (input, start) {
            start = +start; //parse to int
            return input.slice(start)
        }
    })
    .filter('range', function () {
        return function (input, total) {
            total = parseInt(total)
            for (var i = 0; i < total; i++)
                input.push(i)
            return input
        };
    });