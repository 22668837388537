angular.module('JC.directives.form', ['JC.services.form', 'JC.controllers.form'])


      .directive('afgForm', ['$parse', 'Validator', '$rootScope', function ($parse, Validator, $rootScope) {

          return {
              require: ['form', 'afgForm'],
              scope: true,
              controller: [
                "$scope",
                "$element",
                "$attrs",
                'BuildForm',
                function ($scope, $element, $attrs, BuildForm) {

                    if (!angular.element(document.getElementsByTagName("html")[0]).hasClass("ie")) {
                        $element.css("display", "none");
                    }

                    $scope.ready = false;

                    $scope.$watch('ready', function () {
                        $element.addClass("animated").addClass("fadeIn");
                        BuildForm.build($element, $attrs, function () {
                            $element.css("display", "block").css("visibility", "visible");
                        });
                        
                    }, true);

                    // Holds all validators
                    this.validators = {
                        _validators: {},
                        registerValidator: function (name, validator) {
                            validator.profile = $attrs.profile || 'default';
                            this._validators[name] = validator;
                        },
                        deregisterValidator: function (name) {
                            this._validators[name].ngModel.$valid = true;
                            this._validators[name].showErrors();
                            delete this._validators[name];
                        },
                        hasValidator: function (name) {
                            return this._validators[name] !== undefined;
                        },
                        getValidator: function (name) {
                            return this._validators[name];
                        },
                        resetValidity: function (isValid, reset) {
                            angular.forEach(this._validators, function (validator) {
                                validator.resetValidity(isValid, reset);
                            });
                        },
                        showAllErrors: function () {
                            angular.forEach(this._validators, function (validator) {
                                validator.showErrors(true);
                            });
                        }
                    };

                    this.$element = $element;
                }
              ],
              link: function (scope, element, attributes, ctrl) {

                  scope.options = {
                      carp : "false"
                  }

                  scope.options = angular.extend(scope.options, attributes);

                  var control = {
                      onSubmit: function () { }
                  },
                      formCtrl = ctrl[0],
                      xtFormCtrl = ctrl[1];

                  function spinners() {
                      angular.forEach(element.find("button"), function (_btn) {
                          var btn = angular.element(_btn)
                          if (btn.attr("type") == "submit" && btn.find("i").length == 0) {
                              btn.prepend('<i class="fa fa-spinner fa-spin" style="margin-right:10px;"></i> ');
                          }
                      })
                  }

                  function submit() {

                      formCtrl.$setDirty();
                      if (!formCtrl.$valid) {
                          xtFormCtrl.validators.showAllErrors();
                          control.onSubmit(false);
                          return;
                      }

                      spinners()

                      //reset
                      var canSubmit = true

                      if (typeof (window[element.attr("data-afg-onsubmit")]) === "function") {
                          canSubmit = window[element.attr("data-afg-onsubmit")](scope, angular);
                      }

                      if (canSubmit) {
                          document.forms[element.attr("name")].submit();
                      }
                      /*xtFormCtrl.validators.resetValidity();

                      control.onSubmit(true);*/
                  }

                  function validate() {

                      formCtrl.$setDirty();

                      if (!formCtrl.$valid) {
                          xtFormCtrl.validators.showAllErrors();
                          return false;
                      } else {
                          return true;
                      }
                  }

                  scope.reset = function() {
                      xtFormCtrl.validators.resetValidity(false, true);
                  }

                  // add save functionality to the form control
                  // (i got this style from angular-ui. I kind of think it looks like an antipattern but
                  // but I can't find a cleaner way of controller/directive comm)
                  if (attributes.xtForm) {
                      var temp = scope.$eval(attrs.xtForm);
                      if (temp !== undefined) {
                          control = temp;
                          var that = this;
                          control.submit = function () {
                              submit.apply(that, arguments);
                          };
                          control.reset = function () {
                              reset.apply(that, arguments);
                          };
                          control.validate = function () {
                              validate.apply(that, arguments);
                          };
                      }
                  }

                  // wire up default submit of form to save function
                  element.on('submit', function (evt) {
				
					if (angular.lowercase(scope.options.carp) == "true") {
                          alert('Your changes were not saved.\n Read Only Session: Changes are not allowed.');
                      } else {
                          submit();
                      }
					evt.preventDefault();
						return false;
                  });
              }
          };
      }])
      .directive('afgValidate', ['Validator', '$filter', function (InputValidator, $filter) {
          return {
              require: ['ngModel', '^form', '^afgForm'],
              priority: 99,
              scope: true,
              link: function (scope, element, attrs, ctrls) {

                  var ngModel = ctrls[0],
                    formCtrl = ctrls[1],
                    xtFormCtrl = ctrls[2];

                  function formatDate(string) {
                      var date = new Date(string);
                      return date.getUTCFullYear() + "-" + ('0' + (date.getUTCMonth() + 1)).slice(-2) + "-" + ('0' + date.getUTCDate()).slice(-2);
                  }

                  if (ngModel.$name === undefined) { throw new Error('element must have a "name" attribute to use xtValidate'); }
                  
                  if ((element.attr("type") == "checkbox" || element.attr("type") == "radio")){
                    if(element.attr("checked") && element.attr("checked") == "checked"){
                      var value = element.attr("value");
                      ngModel.$setViewValue(value); 
                      element[0].checked = true;
                    }

                    if (element.attr("type") == "checkbox") {
                        var _parent = angular.element(document.getElementById(ngModel.$name + "_group")),
                            _checkboxes = _parent.find("input")

                        angular.forEach(_checkboxes, function (cb, _i) {
                            var Check = angular.element(cb)
                            Check.on("click", function () {
                                var el = angular.element(this),
                                    value = el.val(),
                                    modelValue = null

                                if (element.attr("type") == el.attr("type")) {

                                    angular.forEach(_checkboxes, function (value, index) {

                                        var input = angular.element(value)
                                        if (value.checked) {
                                            console.log("checked")
                                            modelValue = input.attr("ng-true-value")
                                        }
                                    })

                                    ngModel.$setViewValue(modelValue)
                                    if (modelValue != null) {
                                        var errors = angular.element(document.getElementById('error_' + ngModel.$name + ''))
                                        errors.remove()
                                        ngModel.$setValidity("required", true);
                                        ngModel.$error.required = false;
                                        scope.$apply()
                                    } else {
                                    }
                                }
                            })
                        })
                    }
                    
                  } else {
                      if (element.attr("value") && element.attr("value") != " "){
                          if (element.attr("type") === "date") {
                                  //Model -> View
                              var dateString = formatDate(element.attr("value"));

                              element.attr("value", dateString);
                              ngModel.$setViewValue(dateString);
                              
                          } else {
                              ngModel.$setViewValue(element.attr("value"));
                              if (element[0].tagName === "SELECT") {
                                  var children = element.children();
                                  for (var n in element.children()) {
                                      if (angular.element(children[n])[0] != undefined && angular.element(children[n]).attr("value") == element.attr("value")) {
                                          angular.element(children[n]).attr("selected", "selected");
                                      }
                                  }
                              }
                          }
                          element[0].value = element.attr("value");
                      }
                  }

                  /*if (element.is('select') && attrs.placement === undefined) {
                      attrs.placement = 'top';
                  }*/

                  var validator = new InputValidator(scope, element, attrs, ngModel, formCtrl);
                  xtFormCtrl.validators.registerValidator(attrs.name, validator);
                  element.on('$destroy', function () {
                      if (xtFormCtrl.validators.hasValidator(attrs.name)) {
                          xtFormCtrl.validators.deregisterValidator(attrs.name);
                      }
                  });
              }
          };
      }])
      .directive('regexValidate', function () {
          return {
              // restrict to an attribute type.
              restrict: 'A',

              // element must have ng-model attribute.
              require: 'ngModel',

              // scope = the parent scope
              // elem = the element the directive is on
              // attr = a dictionary of attributes on the element
              // ctrl = the controller for ngModel.
              link: function (scope, elem, attr, ctrl) {

                  //get the regex flags from the regex-validate-flags="" attribute (optional)
                  var flags = attr.regexValidateFlags || '';

                  // create the regex obj.
                  var regex = new RegExp(attr.regexValidate, flags);

                  // add a parser that will process each time the value is 
                  // parsed into the model when the user updates it.
                  ctrl.$parsers.unshift(function (value) {
                      // test and set the validity after update.
                      var valid = regex.test(value);
                      ctrl.$setValidity('regexValidate', valid);
                      

                      // if it's valid, return the value to the model, 
                      // otherwise return undefined.
                      return valid ? value : undefined;
                  });

                  // add a formatter that will process each time the value 
                  // is updated on the DOM element.
                  ctrl.$formatters.unshift(function (value) {
                      // validate.
                      ctrl.$setValidity('regexValidate', regex.test(value));

                      // return the value or nothing will be written to the DOM.
                      return value;
                  });
              }
          };
      });