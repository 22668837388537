
angular.module('moduleServicesApp', [
        'JC.controllers.form',
        'JC.services.utilities',
        'JC.services.form',
        'JC.directives.content',
        'JC.directives.datatable',
        'JC.directives.form',
        'JC.directives.utilities',
        'JC.filters',
        'ui.bootstrap',
        'ngResource',
        'ngSanitize',
        'ngTouch',
        'ui.select2'
    ], function($interpolateProvider) {
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');
    })
    .config(function ($sceProvider, $logProvider) {
        // Completely disable SCE to support IE7.
        $sceProvider.enabled(false);
        $logProvider.debugEnabled(false);
    })
    .run(function ($rootScope, $log, $location, $anchorScroll, $window) {

        var _window = angular.element($window)

        $rootScope.navCollapsed = false
        $rootScope.tabs = []
        $rootScope.spyLinks = []
        $rootScope.spyAnchors = []
        $rootScope.tableCount = 0
    	
    });

