angular.module('JC.directives.content', ['JC.services.utilities'])
    .directive('afgContent', ['$http', '$timeout', '$sanitize', 'Base64', '$log', '$compile', function ($http, $timeout, $sanitize, Base64, $log, $compile) {
        return {
            templateUrl: window.OSC_BASE_URL + "js/views/dynamic-content.html",
            restrict: 'AE',
            scope: true,
            link: function (scope, element, attributes) {

                scope.options = {
                    contentUrl: null,
                    allowHtml: "true",
                    showLoader: "true",
                    loadingMessage: "Loading...",
                    onLoad: null,
                };

                scope.options = angular.extend(scope.options, attributes);
                scope.loading = false;
                scope.content = "";
                scope.classes = ""

                if (scope.options.contentUrl) {

                    var encoded = "",
                        url = scope.options.contentUrl;

                    scope.loading = (scope.options.showLoader == "true") ? true : false;

                    scope.$watch("content", function (html) {
                        if(scope.content != ""){
                            element.html(scope.content)
                            angular.forEach(element.children(), function (child) {
                                child = angular.element(child)
                                if (child.hasClass("html-content")) {
                                    child.html(scope.content)
                                }
                            })
                            $compile(element.contents())(scope);
                        }
                    });

                    $http({ method: 'GET', url: url })
                        .success(function (data, status, headers, config) {
                            if (status == 200) {

                                scope.content = (data != null) ? data : ""; //(scope.options.allowHtml == "true") ? $sanitize(data) : String(data).replace(/<[^>]+>/gm, '').replace(/\n/gm, "<br>");

                                if (scope.content == "Sign Up for Direct Deposit") {
                                    scope.classes = "dd-signup"
                                }

                                if (scope.content.indexOf("<head>") > -1) { //if the returned code is a full page, make the string empty
                                    scope.content = ""
                                }
                            }

                            if (typeof (window[scope.options.onLoad]) === "function") {
                                $timeout(function () {
                                    window[scope.options.onLoad](scope, $http, function (_scope) {
                                        scope = _scope;
                                    }, angular);
                                }, 200)
                                
                            }

                        })
                        .error(function (data, status, headers, config) {
                            scope.content = "<p class=\"data-content-load-error\">Could not load Content</p>"
                            $log.error(data);
                        })
                        ["finally"](function () {
                            scope.loading = false;
                        });
                }
            }
        }
    }])
    .directive('afgVideo', function () {
        return {
            restrict: 'AE', //this means the direct must be declared as an attribute on an element
            replace: false, //don't replace the surrounding element with the template code
            link: function (scope, element, attrs) { // manipulate the DOM in here

                scope.displayModal = false
                scope.useFlash = false
                scope.wrapper = makeid()
                scope.container = makeid()
                scope.btnClass = (scope.buttonClass) ? scope.buttonClass : "btn-link"
                scope.video = ''

                var parameters = {
                    src: scope.mp4Url,
                    width: (scope.videoWidth) ? scope.videoWidth : '100%',
                    height: (scope.videoHeight) ? scope.videoHeight : 380,
                    autoPlay: true,
                    controlBarAutoHide: "true",
                    controlBarPosition: "bottom",
                    urlIncludesFMSApplicationInstance: "true",
                    backgroundColor: "#000000",
                    verbose: "true",
                    poster: "",
                    wmode: "opaque"
                };

                scope.$watch("wrapper", function (value) {//I change here
                    if (Browser.Version() < 99) { //if html5 video is not supported start flowplayer
                        scope.useFlash = true
                        makeContainer()
                    }
                })

                function makeContainer() {
                    angular.element(document.getElementById(scope.wrapper)).html('<div id="' + scope.container + '"></div>')
                }

                function makeid() {
                    var text = "";
                    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                    for (var i = 0; i < 5; i++)
                        text += possible.charAt(Math.floor(Math.random() * possible.length));

                    return text;
                }

                scope.showModal = function () {
                    scope.displayModal = true

                    if (Browser.Version() < 99) { //if html5 video is not supported start flowplayer

                        swfobject.embedSWF(window.OSC_BASE_URL + "js/lib/strobemediaplayback.swf",
                               scope.container,
                               parameters["width"],
                               parameters["height"],
                               "9.0.0", {}, parameters,
                               {
                                   allowFullScreen: "true", wmode: parameters["wmode"]
                               },
                               {
                                   name: "StrobeMediaPlayback"
                               });
                    } else {
                        scope.video = ['<video width="100%" height="' + scope.videoHeight + '" poster="' + scope.splashImage + '" controls style="background:black;">',
                                '<source src="' + scope.mp4Url + '" type="video/mp4" />',
                                '<source src="' + scope.webmUrl + '" type="video/webm" />',
                                '<a id="flash-player" style="width:100%, height:' + scope.videoHeight + ' }}px,display:block,margin:0 auto"></a>',
                                '</video>'].join("")
                    }
                }

                scope.hideModal = function () {
                    scope.displayModal = false
                    makeContainer()
                    scope.video = ''
                }
            },
            scope: {
                videoTitle: '@',
                buttonText: '@',
                webmUrl: '@', //binds property value to the element's attribute
                mp4Url: '@',
                videoWidth: '@',
                videoHeight: '@',
                splashImage: '@',
                buttonClass: '@'
            },
            templateUrl: window.OSC_BASE_URL + "js/views/video.html" //contains the video code
        }
    });