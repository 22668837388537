angular.module('JC.directives.utilities', ['JC.services.utilities'])
    .directive('buttonFocus', function ($timeout, $parse) {
        return {
            link: function (scope, element, attrs) {
                var model = $parse(attrs.buttonFocus);
                scope.$watch(model, function (value) {
                	console.log(value)
                    if (value === true) {
                        $timeout(function () {
                            element[0].focus();
                        });
                    }
                });
            }
        };
    })
	.directive('affix', ['$window', function ($window) {
		return {
			$scope: true,
			link: function ($scope, $element, $attrs) {
				var relativeTo = // override or use default: body (html for IE) element
					!!$attrs.relativeTo ? angular.element(window.document.getElementById($attrs.relativeTo)) : 
						!$scope.$root.isIE ? // specify your own isIE check will default to body element
							angular.element(window.document.getElementsByTagName('body')) : 
							angular.element(window.document.getElementsByTagName('html')), // required by IE
					win = !!$attrs.relativeTo ? relativeTo : angular.element($window),
					offsetElement = !!$attrs.offsetElementId ? angular.element(document.getElementById($attrs.offsetElementId)) : null,
					relativeToOffset = !!$attrs.offsetTop ? parseInt($attrs.offsetTop) : 0,
					fixedAt = relativeToOffset;
				
				$scope.affixed = false;
				$scope.relativeHeight = (offsetElement) ? offsetElement[0].offsetHeight : 0;

				win.bind('scroll', checkPosition);

				win.bind('resize', function () {
					$scope.$apply(function () {
						$scope.affixed = false;
					});
					checkPosition();
				});

				function checkPosition() {
					$scope.$apply(function () {
						$scope.affixed = (relativeTo[0].scrollTop > (offsetElement[0].offsetHeight + relativeToOffset)) ? true : false;					
					});
				}
			}
		};
	}])
	.directive("googleChart",['$log', '$window', function($log, $window){  
	    return{
	        restrict : "A",
	        scope: true,
	        link: function($scope, $elem, $attributes){
	            if (typeof google !== "undefined") {

	                $scope.options = {
	                    graphTitle: "",
	                    loadData: null,

	                };
	                //extend options with attributes and add to scope
	                $scope.options = angular.extend($scope.options, $attributes);

	                $scope.dataModel;

	                $scope.$watch('dataModel', function () {
	                    if ($scope.dataModel != null) {
	                        $scope.build();
	                    }
	                });

	                $scope.build = function () {
	                    var dt = $scope.dataModel.dataTable;

	                    var options = {
	                        backgroundColor: 'transparent',
	                        title: $scope.options.graphTitle,
	                        chartArea: { left: 0, top: 20, width: '100%', height: '600px' },
	                        legend: {
	                            position: "labeled"
	                        }
	                    };

	                    if (Browser.Version() == 8) {
	                    	options = {
		                        title: $scope.options.graphTitle,
		                        chartArea: { left: 0, top: 20, width: '200px', height: '600px' },
		                    }
	                    }
	                    var googleChart = new google.visualization[$attributes.googleChart]($elem[0]);
	                    googleChart.draw(dt, options);
	                }

	                if (typeof (window[$scope.options.loadData]) === "function") {
	                    window[$scope.options.loadData]($scope.dataModel, function (dataModel) {
	                        $scope.dataModel = dataModel;
	                        if(Browser.Version() > 9) {
	                        	$scope.build();
	                        }
	                    }, angular, $log);
	                }

	                if(Browser.Version() > 9) {
		                angular.element($window).bind('resize', function () {
		                    if ($scope.dataModel != null) {
		                        $scope.build();
		                    }
		                });
		            }
	            }
	        }
	    }
	}]);